<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.save_filter')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group
                            v-model="form.name"
                            name="filter_name"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button class="mt-2" block @click="save" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import Draggable from "vuedraggable"
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            form: {},
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        save() {
            let params = {
                name: this.form.name,
                table: this.payload.table,
                tableFormat: this.payload.tableFormat,
                tableUniqKey: this.payload.tableUniqKey,
                tableOptions: this.payload.tableOptions,
                excludeBaseParams: this.payload.baseParams,
                excludeAdditionalParams: this.payload.additionalParams,
            }
            let filters = this.payload.filters

            Object.keys(this.payload.baseParams).forEach(key => {
                params[key] = this.payload.baseParams[key]
            })

            Object.keys(this.payload.additionalParams).forEach(key => {
                filters[key] = this.payload.additionalParams[key]
            })

            params['filters'] = filters

            axios.post(window.apiUrl + '/save-table-filter', params, {'skip_loading': true})
                .then(result => {
                    this.commonAfterSave(result)
                }, () => {
                    this.loading = false
                })
        },
    },
}
</script>